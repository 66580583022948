<template>
  <div >
    <app-container :title="'白名单查询'" :background="tool.getThemeList(theme)">
      <ad-banner ref="adBanner"></ad-banner>
      <div class="query-home">
        <div class="cardNum">
          <input
            type="text"
            placeholder="请输入ICCID/SIM"
            v-model.trim="card"
            oninput="this.value = this.value.slice(0,20)"
            id="iccid"
          />
          <div class="iconWrap">
            <van-icon
              name="clear"
              class="cancel"
              @click="card = ''"
              v-if="card.length > 0"
            />
            <img
              class="icon"
              src="../../assets/image/query/card.png"
            />
          </div>
        </div>
        <button class="submit" :disabled="!card.length" @click="getCardInfo">
          查询
        </button>
      </div>
    </app-container>
    
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { isDevice } from "@/utils/utils";
import {
  queryCardInfo
} from "_API_/api.services";
export default {
  setup() {
    const state = reactive({
      theme:'',
      card:''
    });
    const { proxy: ctx } = getCurrentInstance();
    const {  $tool: tool,$store: store, $router: router} = ctx;
    const methods = {
      getCardInfo() {
        queryCardInfo(state.card).then(({ data: res }) => {
          if (res.code == 0) {
            store.dispatch("SetLoginToken",res.data.iccid)
            store.dispatch("SetUserInfo", {
              iccid: res.data.iccid,
              virtualId: res.data.virtualId,
              cardId: res.data.cardId,
              iccids: res.data.iccids,
              sim: res.data.sim,
              appid: res.data.AppID,
              apnId: res.data.apnId,
              money: res.data.money,
              userMoney: res.data.userMoney,
              maxWhitelist: res.data.maxWhitelist,
              operator: res.data.operator,
              passagewayType: res.data.passagewayType,
              nickname: res.data.nickname,
              mobile: res.data.mobile,
              totalVoice:
              res.data.totalVoice !== null ? res.data.totalVoice : "",
              device: isDevice()
            });
            if(state.theme === 'YuYin'){
              return router.replace({
                name:'yyPlatfrom-familyList'
              })
            }
            return router.replace({
              name:'personal-whiteList'
            })
          }
          return tool.toast({
            msg: "查询失败",
            duration: 2500
          })
        });
      },
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
    });
    return { ...toRefs(state), ...methods,tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.query-home {
    height: calc(100vh - 92px);
    overflow-y: auto;
    overflow-x: hidden;
    .cardNum {
      width: 660px;
      line-height: 85px;
      height: 85px;
      margin: 40px auto 0px;
      padding: 0 3%;
      position: relative;
      background: #ffffff;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .cardNum .iconWrap {
      display: flex;
      align-items: center;
    }
    .cardNum img.icon {
      height: 40px;
    }

    .cardNum .cancel {
      font-size: 36px;
      color: #d2d2d2;
      margin-right: 15px;
    }

    .cardNum input {
      width: 80%;
      text-indent: 10px;
      font-size: 30px;
      border: none;
      background: transparent;
      color: black;
      height: 100%;
    }
    .submit {
      width: 700px;
      height: 84px;
      color: #ffffff;
      @include background_color("background_color1");
      box-shadow: 0px 8px 16px 0px #bce1ff;
      border-radius: 20px;
      border: none;
      margin: 48px 50px 48px 26px;
    }
    .submit[disabled] {
      @include background_color("background_color2");
    }
  }
</style>
